import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Filter } from './style';
import SearchBlock from './SearchBlock';
import styles from './HomeSearch.module.scss';
import { IwImage } from '@shared/components/IwImage';
import bgImage from '@assets/images/home/home-search/main-search.jpg';
import HomeSteps from '@components/Home/HomeSearch/HomeSteps';

function HomeSearch() {
  const { t } = useTranslation('home');

  return (
    <section className={styles['home-search']}>
      <IwImage
        src={bgImage}
        objectFit={'cover'}
        objectPosition="40% 0%"
        placeholder="blur"
        layout="fill"
        priority={true}
        alt={`iWorld - ${t('home.filter.title')}`}
      />
      <Filter.Section>
        <Filter.Title>{t('home.filter.title')}</Filter.Title>
        <Filter.SubTitle>{t('home.filter.subTitle')}</Filter.SubTitle>
        <Filter.AutocompleteContainer>
          <SearchBlock />
        </Filter.AutocompleteContainer>
        <HomeSteps />
      </Filter.Section>
    </section>
  );
}

export default HomeSearch;
