import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HomeSearch from './HomeSearch';
import Advantages from './Advantages';
import SubscribeSection from './SubscribeSection';
import PopularServices from './PopularServices';
import useTranslation from 'next-translate/useTranslation';
import LazyLoad from 'react-lazyload';
import dynamic from 'next/dynamic';
import { selectUserCountry } from '@store/getLocation/selectors';
import { useSelector } from 'react-redux';
import timer from '@utils/timer';

const VideoBox = dynamic(() => import('shared/modules/VideoBox'));
const VideoSlider = dynamic(() => import('./VideoSlider'), {
  ssr: false,
});
const SliderBestServices = dynamic(() => import('./Sliders/services'), {
  ssr: false,
});
const Media = dynamic(() => import('./Media'));
const Projects = dynamic(() => import('./Projects'), {
  ssr: false,
});

function Home(props) {
  const { t, lang } = useTranslation('home');
  const userCountry = useSelector(selectUserCountry);
  const [NYBanner, setNYBanner] = useState(false);
  const [MCBanner, setMCBanner] = useState(false);

  const playlist = {
    ru: [
      {
        link: '_tByBC_CNx0',
      },
      {
        link: 'abNK6ZTyAdw',
      },
    ],
    en: [
      {
        link: 'Fvk7HpMdJdc',
      },
    ],
  };

  const playlistNY = {
    ru: [
      {
        link: '71KutTRp3Os',
      },
    ],
    en: [
      {
        link: '64CxSV2CBQI',
      },
    ],
  };

  const playlistMC = {
    ru: [
      {
        link: 'dXp5kIq7qsQ',
      },
    ],
    en: [
      {
        link: 'vn-v2wN2RA4',
      },
    ],
  };

  useEffect(() => {
    if (lang === 'ru') {
      setNYBanner(
        userCountry === 'RU' || userCountry === 'BY'
          ? timer('01/01/2025', '01/06/2025')
          : timer('01/01/2025', '01/07/2025'),
      );
      setMCBanner(
        userCountry === 'RU' || userCountry === 'BY'
          ? timer('01/06/2025', '01/08/2025')
          : timer('12/23/2024 10:00', '12/26/2024'),
      );
    } else {
      setNYBanner(userCountry === 'RU' || userCountry === 'BY' ? false : timer('01/01/2025', '01/07/2025'));
      setMCBanner(userCountry === 'RU' || userCountry === 'BY' ? false : timer('12/25/2024', '12/28/2024'));
    }
  }, [userCountry]);

  return (
    <>
      <HomeSearch />
      <Advantages />
      <LazyLoad offset={150} once>
        {NYBanner || MCBanner ? (
          <VideoBox
            slider
            playlist={NYBanner ? playlistNY[lang] : playlistMC[lang]}
            title={NYBanner ? t('newYear') : t('merryChristmas')}
            description={NYBanner ? t('newYearDescription') : t('merryChristmasDescription')}
            withBg
            textCenter
          />
        ) : (
          <VideoBox
            slider
            playlist={playlist[lang]}
            title={t('home.video.title')}
            description={t('home.video.description')}
            withBg
          />
        )}
      </LazyLoad>
      <PopularServices list={props.mostPopularList} />
      <LazyLoad offset={150} once>
        <Media />
      </LazyLoad>
      <SubscribeSection blogList={props.blogList} />
      <LazyLoad offset={150} once>
        <Projects />
      </LazyLoad>
      <LazyLoad offset={150} once>
        <SliderBestServices />
      </LazyLoad>
      <LazyLoad offset={150} once>
        <VideoSlider />
      </LazyLoad>
    </>
  );
}

Home.propTypes = {
  countryGroupsData: PropTypes.array,
  mostPopularList: PropTypes.array,
  newsList: PropTypes.array,
  blogList: PropTypes.array,
};

export default Home;
