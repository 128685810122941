import { useState, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { selectAllCountries, loadingAndErrors } from 'store/allCountries';
import { loadingAndErrors as loadErr, selectSearchInit } from 'store/searchInit';
import { geoLocationActions } from 'store/getLocation';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'antd/lib/select';
import { ArrowDown } from '@icons/Svg/ArrowIcon/ArrowDown';
import PropTypes from 'prop-types';
import CountrySelect from './CountrySelect';
import { Style } from './style';
import { useRouter } from 'next/router';
import servicesCountryList from '@assets/data/services/country-codes';
import { Loader } from '@shared/components/Loader';

const { Option } = Select;

const SearchBlock = ({ geoLocation }) => {
  // HOOK
  const dispatch = useDispatch();
  const data = useSelector(selectAllCountries);
  const data_search = useSelector(selectSearchInit);
  const { isLoading } = useSelector(loadingAndErrors());
  const loading_info = useSelector(loadErr());
  const { t } = useTranslation('home');
  const [searchCategories, setSearchCategories] = useState({});
  const router = useRouter();

  useEffect(() => {
    dispatch(geoLocationActions.getGeoLocation.trigger());
  }, []);

  // CONST
  const { countries } = data?.data || {};
  const sortedCountriesList =
    countries &&
    [...countries]
      .filter((country) => servicesCountryList.find((i) => i.id === country.id))
      .sort((a, b) => b.destination_order - a.destination_order);

  const selectKeys = [
    { countryKey: 'source_country', title: 'home.filter.residence', list: countries },
    { countryKey: 'target_country', title: 'home.filter.target', list: sortedCountriesList },
  ];

  // HOOK
  useEffect(() => {
    if (geoLocation) {
      const userCountry = countries?.find(({ code }) => code === geoLocation);
      setSearchCategories({ ...searchCategories, source_country: userCountry?.id });
    }
  }, [countries, geoLocation]);
  // FUNCTION
  const handleChangeFrom = (value, key) => setSearchCategories({ ...searchCategories, [key]: value });

  const handleSearchButton = () => {
    let query = {};
    Object.keys(searchCategories).forEach((key) => {
      if (searchCategories[key]) query = { ...query, [key]: searchCategories[key] };
    });

    router.push({
      pathname: `/services`,
      query,
    });
  };

  return (
    <>
      {selectKeys.map(({ countryKey, title, list }, ndx) => (
        <CountrySelect
          key={ndx}
          list={list}
          searchCategories={searchCategories}
          handleChangeFrom={handleChangeFrom}
          isLoading={isLoading}
          countryKey={countryKey}
          title={t(title)}
          loader={Loader}
        />
      ))}
      <Style.Box>
        <Style.Title>{t('home.filter.immigration')}</Style.Title>
        <Style.InputWrap purpose>
          <Select
            placeholder={t('home.filter.immigration')}
            allowClear
            popupClassName="DDHome"
            suffixIcon={<ArrowDown fill="#2E2E2E" />}
            value={searchCategories?.immigration_purposes}
            notFoundContent={loading_info.isLoading ? Loader : null}
            onChange={(val) => handleChangeFrom(val, 'immigration_purposes')}
            listHeight="288"
            role="listbox"
            aria-label={t('home.filter.immigration')}>
            {Object.entries(data_search).length !== 0 &&
              data_search.data.immigration_purpose.map((item, ndx) => (
                <Option key={ndx} value={item.id} role="option">
                  <Style.OptionBox>{item.name}</Style.OptionBox>
                </Option>
              ))}
          </Select>
        </Style.InputWrap>
      </Style.Box>
      <Style.Search data-type="home_search_button" onClick={handleSearchButton}>
        {t('home.filter.search')}
      </Style.Search>
    </>
  );
};
SearchBlock.propTypes = {
  geoLocation: PropTypes.string,
  locationError: PropTypes.string,
  isLoadingLocation: PropTypes.bool,
};

const mapStateToProps = ({ geoLocation, searchCompanies }) => {
  return {
    searchCompanies,
    geoLocation: geoLocation.item,
    locationError: geoLocation.errorMessage,
    isLoadingLocation: geoLocation.isLoading,
  };
};
export default connect(mapStateToProps)(SearchBlock);
