import React, { memo, useEffect, useState } from 'react';
import ErrorModal from 'shared/modules/ErrorModal';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { setUTM } from '@utils/helper/ga.js';
import { NextHeadContent } from './NextHeadContent';
import dynamic from 'next/dynamic';
import Header from '../Header';
import Footer from '../Footer';
import { HeaderBanner } from '@shared/components/banners/HeaderBanner';
import { Loader } from '@shared/components/Loader';
import { IS_SERVER } from '@src/constants/global';
import OneSignal from 'react-onesignal';
import timer from '@utils/timer';
import { selectUserCountry } from '@store/getLocation/selectors';
import { useSelector } from 'react-redux';

const BannerRealEstate = dynamic(() => import('@shared/components/banners/BannerRealEstate'));

const Layout = (props) => {
  const { children, removeFooter, removeFooterXS } = props;
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const { t, lang } = useTranslation('error');
  const userCountry = useSelector(selectUserCountry);

  const OneSignalAPImap = {
    en: 'ea7e6081-392c-4dcf-afeb-0c20efddd06f',
    ru: 'f8e4444c-cbee-4150-a8ab-5b192e6ce7d9',
  };

  useEffect(() => {
    OneSignal.init({ appId: OneSignalAPImap[lang] || 'f8e4444c-cbee-4150-a8ab-5b192e6ce7d9' });
    setLoading(false);
    setUTM();
  }, []);

  useEffect(() => {
    setShowBanner(
      userCountry === 'RU' || userCountry === 'BY'
        ? timer('12/30/2024', '01/09/2025')
        : timer('12/22/2024', '01/09/2025'),
    );
  }, [userCountry]);

  return (
    <>
      <NextHeadContent title={props.title} description={props.description} />
      {showBanner && <HeaderBanner />}
      <Header />
      <div className={`main ${!!props.addClass && props.addClass}`}>
        {children}
        {!props.hideBanner && <BannerRealEstate type={'horizontal'}></BannerRealEstate>}
      </div>
      <Footer removeFooter={removeFooter} removeFooterXS={removeFooterXS} />
      {IS_SERVER && <Loader />}
      <ErrorModal title={t('errorHasOccurred')} description={t('sorryPleaseLater')} textButton={t('modalContinue')} />
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  hideBanner: PropTypes.any,
  addClass: PropTypes.string,
  removeFooter: PropTypes.bool,
  removeFooterXS: PropTypes.bool,
};

export default memo(Layout);
